
.App {
  text-align: center;
}


.logo {
  height: auto;
   max-width: 100%;
}


@media screen and (max-width: 480px) {
  .App img {
    display: flex;
    margin: 0 auto;
    min-width: 270%
  }
}
