.navbar {
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.15) 0px -2px 10px 0px;
  justify-content: center;
  background-color: grey;
  align-items:  center;
  padding: 0.5em 9em;
}

.brand {
  font-size: 1.2em;
  color: white;
}

.brand span {
  font-weight: 900;
  text-transform: uppercases;
}

.links_list {
  display: flex;
  list-style: none;
}

.links_list li {
  margin-right: 1em;
}

.links_list li a {
  padding: 0.4em 0.6em;
}

.active {
  background-color: #000;
  color: #fff;
}

@media screen and (max-width: 480px) {
  .navbar {
    width: 200%;
    align-items:  center;
    justify-content: center;
    height: auto;
  }
}

@media screen and (max-width: 768px) {
 .navbar {
   width: 200%;
   align-items:  center;
   justify-content: center;
   height: auto;
 }
}


