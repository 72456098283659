body{
  background-color: #F6FCFF;
  padding: 0;
  margin: 0;
  font-family:"Montserrat", sans-serif ;
}

input::placeholder,
textarea::placeholder {
  font-family: "Montserrat", sans-serif;
}

input:focus,
textarea:focus {
  outline: none;
}

a,
nav button {
  text-decoration: none;
  background-color: transparent;
  border: none;
  color: #000;
  transition: 0.4s;
  font-size: 1em;
  cursor: pointer;
  font-family: "Montserrat", sans-seri;
}

a:hover,
nav button:hover {
  color: #bbb;
}